<template>
  <v-text-field
    ref="autocomplete"
    v-model.trim="innerValue"
    color="tertiary"
    :append-icon="prependIcon"
    :counter="counter"
    :id="elementId"
    :readonly="readonly"
    :label="label"
    :error-messages="errorMessages"
    :loading="loading"
    :suffix="suffix"
    placeholder=""
  />
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-05 17:37:31
   * @modify date 2021-01-05 17:37:31
   * @desc AddressPicker
   */
  import UUID from "uuidjs";
  import { mountGooglePlacesAutocomplete } from "@/utils/googlePlacesUtils";

  export default {
    name: "AddressPicker",
    props: {
      value: String,
      address: Object,
      withGmt: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      counter: {
        type: [String, Number],
        default: 512
      },
      label: {
        type: String,
        default() {
          return this.$t("global.address");
        }
      },
      prependIcon: {
        type: String,
        default: "mdi-magnify"
      }
    },
    data: vm => ({}),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(address) {
          this.$emit("input", address);
        }
      },
      elementId() {
        return UUID.genV1().hexString;
      },
      formattedGmt() {
        let gmt = this.gmt || 0;
        let sign = gmt >= 0;
        let totalMinute = Math.abs(gmt);
        let hour = totalMinute / 60,
          minute = totalMinute % 60;
        let formattedHour = this.$moment
          .utc()
          .hours(hour)
          .minutes(minute)
          .format("HH:mm");
        return "GMT" + (sign ? "+" + formattedHour : "-" + formattedHour);
      },
      suffix() {
        return this.withGmt ? this.formattedGmt : "";
      }
    },
    methods: {
      setAddress(address) {
        this.innerValue = address.formatted_address && address.formatted_address.substring(0, parseInt(this.counter));
        const computedAddress = {};
        let cityFound = false;
        address.address_components.forEach(addressComponent => {
          if (addressComponent.types.includes("country")) {
            computedAddress.country = addressComponent.long_name;
          } else if (addressComponent.types.includes("locality")) {
            computedAddress.locality = addressComponent.long_name;
          } else if (addressComponent.types.includes("administrative_area_level_1")) {
            computedAddress.administrative_area_level_1 = addressComponent.long_name;
          } else if (addressComponent.types.includes("administrative_area_level_2")) {
            computedAddress.administrative_area_level_2 = addressComponent.long_name;
          } else if (addressComponent.types.includes("administrative_area_level_3")) {
            computedAddress.administrative_area_level_3 = addressComponent.long_name;
          } else if (addressComponent.types.includes("administrative_area_level_4")) {
            computedAddress.administrative_area_level_4 = addressComponent.long_name;
          } else if (addressComponent.types.includes("postal_code")) {
            computedAddress.postal_code = addressComponent.long_name;
          }
        });
        computedAddress.utc_offset_minutes = address.utc_offset_minutes;
        computedAddress.lat = address.geometry.location.lat();
        computedAddress.lng = address.geometry.location.lng();
        this.$emit("change", { ...computedAddress });
      },
      initAutocomplete() {
        this.$gmapApiPromiseLazy().then(() => {
          mountGooglePlacesAutocomplete(document.getElementById(this.elementId), address => {
            if (address && address.formatted_address) {
              this.setAddress(address);
            }
          });
        });
      }
    },
    mounted() {
      this.initAutocomplete();
    }
  };
</script>

<style></style>
