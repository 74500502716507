export const mountGooglePlacesAutocomplete = function(_element, _placeChangedCallback, configuration = {}) {
  try {
    let autocomplete = new window.google.maps.places.Autocomplete(_element, configuration);
    if (autocomplete) {
      _element.setAttribute("placeholder", ""); // necessary for removing Enter Location hint
      autocomplete.addListener("place_changed", () => {
        _placeChangedCallback(autocomplete.getPlace());
      });
    }
  } catch (e) {
    console.error("Maps Error:", e.toString());
  }
};
